import React, { useState } from "react";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import {
  Button,
  createStyles,
  makeStyles,
  TextField,
  Tooltip,
} from "@material-ui/core";
import config from "../../config";
import DepartmentSelect from "../DepartmentSelect";
import OfficeSelect from "../OfficeSelect";
import Modal from "../Modal";
import { useAuth } from "../../context/Auth";

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    root: {
      "& > *": {
        margin: spacing(1),
        width: "33ch",
      },
    },
    fullWidth: {
      width: `calc(100% - ${spacing(2)}px)`,
    },
  })
);

const AddParticipant = ({ eventId, setReload }: any) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<any>();
  const { fetchWithUser } = useAuth()
  const classes = useStyles();

  const setValue = (key: string, value: any) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const create = () => {
    const url = `${config.apiUrl}/Employee/AddEmployee`;
    fetchWithUser(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then((id) => {
        const url = `${config.apiUrl}/HolidayGift/AddEmployeesToGiftEvent?eventID=${eventId}`;
        fetchWithUser(url, {
          method: 'POST',
          body: JSON.stringify([+id]),
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((_) => {
            setReload(true);
            setOpen(false)
          });
      });
  };

  return (
    <>
      <Tooltip title="Adds single participant" placement="top">
        <Button
          variant="contained"
          disableElevation
          size="medium"
          color="primary"
          onClick={() => setOpen(true)}
        >
          <PersonAddIcon />
          Add Single
        </Button>
      </Tooltip>
      <Modal
        title="Add Participant"
        open={open}
        onSave={create}
        maxWidth="lg"
        fullWidth={true}
        onClose={() => setOpen(false)}
      >
        <form className={classes.root}>
          <TextField
            required
            variant="outlined"
            label="First Name"
            name="firstName"
            onChange={(e) => setValue("firstName", e.target.value)}
          ></TextField>
          <TextField
            required
            variant="outlined"
            label="Last Name"
            name="lastName"
            onChange={(e) => setValue("lastName", e.target.value)}
          ></TextField>
          <TextField
            required
            variant="outlined"
            label="Nick First Name"
            name="nickFirstName"
            onChange={(e) => setValue("nickFirstName", e.target.value)}
          ></TextField>
          <TextField
            required
            variant="outlined"
            label="Nick Last Name"
            name="nickLastName"
            onChange={(e) => setValue("nickLastName", e.target.value)}
          ></TextField>
          <TextField
            required
            variant="outlined"
            label="Email"
            name="mail"
            onChange={(e) => setValue("mail", e.target.value)}
          ></TextField>
          <TextField
            required
            dir="rtl"
            name="firstNameHeb"
            variant="outlined"
            label="שם פרטי"
            onChange={(e) => setValue("firstNameHeb", e.target.value)}
          />
          <TextField
            required
            dir="rtl"
            name="lastNameHeb"
            variant="outlined"
            label="שם משפחה"
            onChange={(e) => setValue("lastNameHeb", e.target.value)}
          />
          <TextField
            dir="rtl"
            variant="outlined"
            label="מספר במכפל"
            name="employeeID"
            onChange={(e) => setValue("employeeID", e.target.value)}
          ></TextField>
          <TextField
            required
            variant="outlined"
            label="Phone"
            name="phone"
            onChange={(e) => setValue("phone", e.target.value)}
          ></TextField>
          <OfficeSelect onChange={(id) => setValue("officeID", id)} />
          <DepartmentSelect onChange={(id) => setValue("departmentID", id)} />
        </form>
      </Modal>
    </>
  );
};

export default AddParticipant;
