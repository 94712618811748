import config from "../../../config";
import { useAuth } from "../../../context/Auth";
import ExaminationType from "../../../entities/ExaminationType";
import DeleteButton from "../../DeleteButton";
import EditButton from "../../EditButton";

export interface LevelActionsProps {
  setReload: (fetch: boolean) => {};
  setOpen: (fetch: boolean) => {};
  examinationType: ExaminationType;
  setExaminationType: (examinationType: ExaminationType) => void;
}

const LevelActions = ({
  examinationType,
  setOpen,
  setReload,
  setExaminationType,
}: LevelActionsProps) => {
  const { fetchWithUser } = useAuth()

  const deleteLevel = () => {
    if (
      window.confirm(`Are you sure you want to delete `)
    ) {
      const url = `${config.apiUrl}/Examination/RemoveExaminationType?examinationTypeId=${examinationType.examinationTypeID}`;
      fetchWithUser(url,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(() => {
          setReload(true);
        })
    }
  };

  return (
    <>
      <div
        style={{ cursor: "pointer" }}
      >
        <DeleteButton
          disabled={examinationType.participantsNumber ? true : false}
          onClick={() => {
            deleteLevel();
          }}
        />
        <EditButton color="primary"
          onClick={() => {
            setExaminationType(examinationType);
            setOpen(true);
          }}
        />
      </div>
    </>
  );
};

export default LevelActions;
