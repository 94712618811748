import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import config from "../../config";
import Event from "../../entities/Event";
import LotteriesList from "./LotteriesList";
import MainContainer from "../MainContainer";
import Tabs from "../event/Tabs";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import ParticipantsList from "../lotteryPracticipants/ParticipantsList ";
import LockedEvent from "../event/LockedEvent";
import LotteryButton from "../LotteryButton";
import { useAuth } from "../../context/Auth";

export default function LotteryPape() {
  const [event, setEvent] = useState<Event | null>(null);
  const { id } = useParams<{ id: string }>();
  let { path, url } = useRouteMatch();
  const { fetchWithUser } = useAuth()

  useEffect(() => {
    fetchWithUser(`${config.apiUrl}/Event/GetEvent/${id}`)
      .then((response) => response.json())
      .then((event: Event) => setEvent(event));
  }, [id, fetchWithUser]);

  const links = [
    {
      label: "Raffle",
      url: `${url}/lotteries`,
    },
    {
      label: "Participants",
      url: `${url}/lotteriesParticipants`,
    },
  ];

  return (
    <MainContainer lotteryButton={<LotteryButton eventId={id} />} title={event?.eventName}>
      {event?.isLock ? (<LockedEvent event={event} />) : (
        <>
          <Tabs tabs={links}></Tabs>
          <Switch>
            <Route path={`${path}/lotteries`}>
              <LotteriesList eventId={id}></LotteriesList>
            </Route>
            <Route exact path={`${path}/lotteriesParticipants`}>
              <ParticipantsList eventId={id}></ParticipantsList>
            </Route>
            <Redirect from={`${path}`} to={`${path}/lotteries`} />
          </Switch>
        </>
      )}
    </MainContainer>
  );
}