import React, { useEffect, useState } from "react";
import Gift from "../../entities/Gift";
import config from "../../config";
import DataGrid from "../DataGrid";
import GiftActions from "./GiftActions";
import { Avatar } from "@material-ui/core";
import { GridValueGetterParams } from "@material-ui/data-grid";
import { useAuth } from "../../context/Auth";

const generateColumns = (props: any, gifts: Gift[]) => [
  { field: "holidayGiftID", headerName: "ID", hide: true },
  {
    field: "holidayGiftName",
    headerName: "Gift name",
    width: 200,
  },
  {
    field: "holidayGiftDescription",
    headerName: "Description",
    width: 400,
  },
  {
    field: "imagePath",
    headerName: " ",
    sortable: false,
    width: 70,
    renderCell: (params: any) => {
      const { holidayGiftName, imageID } = params.row;
      return imageID ? (
        <Avatar
          alt={holidayGiftName}
          src={`${config.apiUrl}/Image/GetImage/${imageID}`}
          variant="rounded"
        />
      ) : (
        ""
      );
    },
  },
  {
    field: "order",
    headerName: "Order",
    width: 120
  },
  {
    field: "selectionCount",
    headerName: "Total Selections",
    width: 300,
  },
  {
    field: "isOutOfStock",
    headerName: "Is out of stock",
    width: 200,
    valueGetter: (params: GridValueGetterParams) => {
      const { isOutOfStock } = params.row;
      return isOutOfStock ? "Yes" : "No";
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    width: 140,
    renderCell: (params: any) => {
      return <GiftActions gift={params.row} {...props}></GiftActions>;
    },
  },
];

const getRowId = (row: any) => row.holidayGiftID;

export default function GiftsTable(props: any) {
  const { reload, setReload, eventId, setCount } = props;
  const [gifts, setGifts] = useState<Gift[]>([]);
  const [totalSum, setTotalSum] = useState(0);
  const { fetchWithUser } = useAuth()

  useEffect(() => {
    setTotalSum(gifts.reduce(
      (prevValue: any, currentValue: any) => prevValue + currentValue.selectionCount, 0))
  }, [gifts]);

  useEffect(() => {
    if (reload) {
      const url = `${config.apiUrl}/HolidayGift/GetHolidayGiftList/${eventId}`;
      fetchWithUser(url)
        .then((response) => response.json())
        .then((data) => {
          setGifts(data);
          setCount(data.length);
        });
      setReload(false);
    }
  }, [reload, setReload, eventId, setCount, fetchWithUser]);

  return (
    <DataGrid
      namespace="gift"
      rows={gifts}
      columns={generateColumns(props, gifts)}
      getRowId={getRowId}
      components={{
        Footer: () => <h4 style={{ textAlign: "center" }}>Total gifts chosen : {totalSum} </h4>
      }}
    />
  );
}