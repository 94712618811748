import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import config from "../config";
import { FormControl, LinearProgress } from "@material-ui/core";
import { useAuth } from "../context/Auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: "none",
    },
    label: {
      width: "100%",
    },
  })
);

interface Props {
  setId: (url: number) => void;
}

export default function UploadButton({ setId }: Props) {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { fetchWithUser } = useAuth()

  const onChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      upload(file);
    }
  };

  const upload = (file: File) => {
    setLoading(true);
    const form = new FormData();
    form.append("image", file);
    fetchWithUser(
      `${config.apiUrl}/Image/Upload`,
      {
        method: 'POST',
        body: form
      }
    )
      .then((response) => response.json())
      .then((id: number) => {
        setLoading(false);
        setId(id);
      })
      .catch((_) => setLoading(false));
  };

  return (
    <FormControl>
      <input
        accept="image/*"
        className={classes.input}
        id="upload-file"
        type="file"
        onChange={onChange}
      />
      <label htmlFor="upload-file" className={classes.label}>
        <Button
          variant="contained"
          component="span"
          color="primary"
          fullWidth={true}
        >
          <AddPhotoAlternateIcon />
          Upload a photo
        </Button>
      </label>
      {loading && <LinearProgress />}
    </FormControl>
  );
}
