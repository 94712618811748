import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import './Calendar.css'
import config from "../../config";
import { getLocalDateFromUtc } from './FormatDate';
import RenderSidebar from './CalendarEventsList'
import { Grid } from '@material-ui/core'
import { parse, addYears } from 'date-fns';
import Moment from 'moment';
import { useCallback, useEffect, useState } from 'react'
import { useAuth } from '../../context/Auth'

export default function Calendar() {

    const [currentEvents, setCurrentEvents] = useState<any[]>([]);
    const [hebrewDates, setHebrewDates] = useState<any[]>([]);
    const [reload, setReload] = useState<boolean>(true);
    const { fetchWithUser } = useAuth()

    function renderEventContent(eventInfo: any) {
        console.log(eventInfo)
        const type = eventInfo.event._def.extendedProps.calenderEventType
        switch (type) {
            case 1:
                eventInfo.backgroundColor = "yellow"
                break;
            case 2:
                eventInfo.backgroundColor = "red"
                break;
            case 3:
                eventInfo.backgroundColor = "pink"
                break;
            case 4:
                eventInfo.backgroundColor = "grey"
                break;
            case 5:
                eventInfo.backgroundColor = "blue"
                break;
            default:
                eventInfo.backgroundColor = "white"
                eventInfo.textColor = "black"
                eventInfo.isDraggable = false
                eventInfo.isEndResizable = false
        }
        return (
            <div style={{ border: eventInfo.backgroundColor }} id={"event"}>
                <b>{formatDate(eventInfo.start, { year: 'numeric', month: 'short', day: 'numeric' })}</b>
                <i style={{ backgroundColor: eventInfo.backgroundColor, color: eventInfo.textColor, direction: "rtl" }}>{eventInfo.event._def.title}</i>
            </div>
        )
    }

    const getCalendarEventList = useCallback((data: any) => {
        const url = `${config.apiUrl}/Calendar/GetCalendarEventsList`;
        fetchWithUser(url)
            .then((response) => response.json())
            .then((data) => {
                data.map((e: any) => {
                    return (
                        e.start = getLocalDateFromUtc(parse(e.start, 'dd-MM-yyyy', new Date())),
                        e.end = getLocalDateFromUtc(parse(e.start, 'dd-MM-yyyy', new Date())),
                        e.allDay = true
                    )
                })
                setCurrentEvents(data)
            }).then(() => {
                if (reload) {
                    var momentVariable = Moment(data, 'DD-MM-YYYY');
                    var stringvalue = momentVariable.format('YYYY-MM-DD');
                    const date = new Date(stringvalue)
                    const finallDate = addYears(date, 2)
                    const d = finallDate.toLocaleDateString()
                    const lastDate = Moment(d, 'DD-MM-YYYY')
                    const url1 = (`https://www.hebcal.com/hebcal/?cfg=fc&v=1&i=off&maj=on&min=on&nx=on&mf=on&ss=on&mod=on&lg=h&s=off&d=on&start=${stringvalue}&end=${lastDate.format('YYYY-MM-DD')}`);
                    fetch(url1).then(response => response.json())
                        .then(data => {
                            setHebrewDates(data)
                        })
                }
            })
    }, [reload, fetchWithUser]);


    const getCurrentSHLCycleDate = useCallback(() => {
        const url2 = `${config.apiUrl}/SHLCycle/GetCurrentSHLCycleDate`;
        fetchWithUser(url2)
            .then((response) => response.json())
            .then((data) => {
                getCalendarEventList(data);
            })
    }, [getCalendarEventList, fetchWithUser]);

    useEffect(() => {
        if (reload) {
            getCurrentSHLCycleDate();
        }
        setReload(false)
    }, [getCurrentSHLCycleDate, reload]);

    const handleEventClick = (event: any) => {
        if (event.event._def.extendedProps.calenderEventType && window.confirm(`Are you sure you want to delete the event '${event.event.title}'`)) {
            var eventData = {
                id: event.event.id,
                title: event.event.title,
                start: getLocalDateFromUtc(parse(event.event.start, 'dd-MM-yyyy', new Date())),
                calenderEventType: event.event._def.extendedProps.calenderEventType,
                allDay: false
            }
            const url = `${config.apiUrl}/Calendar/DeleteCalendarEvent`;
            fetchWithUser(url, {
                method: 'POST',
                body: JSON.stringify(eventData),
                headers: {
                    "Content-Type": "application/json",
                }
            })
            event.event.remove()
        }
    }

    const handleEvents = () => {
    }

    const handleDrop = (event: any) => {
        console.log("event")
        console.log(event)

        event.allDay = false
        if (!event.event._def.extendedProps.calenderEventType) {
            getCurrentSHLCycleDate()
        }
        else {
            if (event.event._def.extendedProps.calenderEventType && window.confirm("Are you sure you want to change the event date")) {
                var eventData = {
                    id: event.event.id,
                    title: event.event.title,
                    start: getLocalDateFromUtc(event.event.start),
                    calenderEventType: event.event._def.extendedProps.calenderEventType,
                }
                const url = `${config.apiUrl}/Calendar/UpdateCalendarEvent`;
                fetchWithUser(url, {
                    method: 'POST',
                    body: JSON.stringify(eventData),
                    headers: {
                        "Content-Type": "application/json",
                    },
                }).then(() => {
                    let prevEvents = [...currentEvents]
                    prevEvents.find((e) => e.id === parseInt(event.event.id)).start = event.event.start
                    setCurrentEvents(prevEvents)
                }).then(() => {
                    getCurrentSHLCycleDate();
                })
            }
            else {
                getCurrentSHLCycleDate();
            }
        }
    }

    return (
        <div className='calendar'>
            <div className='calendar-main'>
                <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    headerToolbar={{
                        right: 'today prev,next',
                        center: '',
                        left: 'title'
                    }}
                    initialView='dayGridMonth'
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    events={[...currentEvents, ...hebrewDates]}
                    weekends={true}
                    initialEvents={[...currentEvents, ...hebrewDates]}
                    eventContent={renderEventContent}
                    eventClick={handleEventClick}
                    eventsSet={handleEvents}
                    eventDrop={handleDrop}
                />
            </div>
            <Grid justifyContent="flex-end"
                alignItems="flex-end">
                {<RenderSidebar currentEvents={currentEvents} />}
            </Grid>
        </div>
    )
}