import React, { useEffect, useState } from "react";
import config from "../../config";
import {
  GridToolbarExport,
  GridValueGetterParams,
} from "@material-ui/data-grid";
import ParticipantActions from "./ParticipantActions";
import { createStyles, Link, makeStyles, Tooltip } from "@material-ui/core";
import DataGrid from "../DataGrid"
import "../../styles/ParticipantsTable.css";
import { useAuth } from "../../context/Auth";

const generateColumns = (props: any, departments: any) => [
  {
    field: "displayName",
    headerName: "Name",
    width: 150,
    valueGetter: (params: GridValueGetterParams) => {
      const { firstName, lastName } = params.row.employee;
      return `${lastName}, ${firstName}`;
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 200,
    valueGetter: (params: GridValueGetterParams) => params.row.employee.mail,
    renderCell: (params: any) => {
      const email = params.row.employee.mail;
      return <Link href={`mailto:${email}`}>{email}</Link>;
    },
  },
  {
    field: "gift",
    headerName: "Selected Gift",
    width: 200,
    valueGetter: (params: GridValueGetterParams) => {
      const holidayGiftName = params.row.holidayGift?.holidayGiftName;
      return holidayGiftName;
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 120,
    valueGetter: (params: GridValueGetterParams) => {
      const { holidayGiftID } = params.row;
      return holidayGiftID ? "Done" : "";
    },
  },
  {
    field: "comment",
    headerName: "Comment",
    cellClassName: 'comment--cell',
    width: 300,
    renderCell: (params: any) => {
      const { comment } = params.row;
      return (comment && <Tooltip title={comment} placement="top-start" arrow><span>{comment}</span></Tooltip>);
    }
  },
  {
    field: "collectOffice",
    headerName: "Collect at",
    width: 130,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.officeCollect?.officeName
    },
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 150,
    valueGetter: (params: GridValueGetterParams) => {
      const { phone } = params.row.employee;
      return phone;
    },
  },
  {
    field: "department",
    headerName: "Department",
    width: 140,
    valueGetter: (params: GridValueGetterParams) => {
      const { departmentID } = params.row.employee;
      const department = departments.find((d: any) => d.key === departmentID);
      return department?.value;
    },
  },
  {
    field: "office",
    headerName: "Office",
    width: 120,
    valueGetter: (params: GridValueGetterParams) => {
      const { office } = params.row.employee;
      return office.officeName;
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    disableExport: true,
    width: 120,
    renderCell: (params: any) => {
      return (
        <ParticipantActions
          participant={params.row}
          {...props}
        ></ParticipantActions>
      );
    },
  },
];

const getRowId = (row: any) => row.employee.employeeID;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: "12px",
      "& .comment--cell": {
        lineHeight: "unset !important",
        maxHeight: "none !important",
        whiteSpace: "normal"
      },
    },
    toolbar: {
      display: "none",
    },
  })
);



export default function ParticipantsTable(props: any) {
  const { setIds, rows } = props;
  //const [participants, setParticipants] = useState<Participant[]>([]);
  const [departments, setDepartments] = useState<any>([]);
  const classes = useStyles();
  const { fetchWithUser } = useAuth()

  useEffect(() => {
    fetchWithUser(`${config.apiUrl}/CodeTable/GetDepartmentList`)
      .then((response) => response.json())
      .then((data) => {
        setDepartments(data);
      });
  }, [fetchWithUser]);

  return (
    <>
      <DataGrid
        namespace="participants"
        className={classes.root}
        rows={rows}
        columns={generateColumns(props, departments)}
        getRowId={getRowId}
        checkboxSelection
        components={{
          Toolbar: () => (
            <GridToolbarExport className={classes.toolbar} />
          ),
        }}
        onSelectionModelChange={setIds}
      />
    </>
  );
}