import React, { useEffect, useState } from "react";
import AddButton from "../../AddButton";
import LevelsTable from "./LevelsTable";
import { createStyles, createTheme, Grid, makeStyles, Typography } from "@material-ui/core";
import LevelEditor from "./LevelEditor";
import MainContainer from "../../MainContainer";
import QuickSearchToolbar from "../../QuickSearchToolbar";
import ExaminationType from "../../../entities/ExaminationType";
import config from "../../../config";
import { escapeRegExp } from "@material-ui/data-grid";
import NoiseControlOffIcon from '@mui/icons-material/NoiseControlOff';
import { useAuth } from "../../../context/Auth";

const defaultTheme = createTheme();
const useStyles = makeStyles(
    () =>
        createStyles({
            title: {
                fontSize: "24px",
                fontStyle: "normal",
                lineHeight: "32px",
                letterSpacing: "0px",
            }
        }),
    { defaultTheme }

);

const LevelsList = () => {
    const [reload, setReload] = useState(true);
    const [open, setOpen] = useState(false);
    const [examinationType, setExaminationType] = useState<any>({});
    const [examinationTypes, setExaminationTypes] = useState<ExaminationType[]>([]);
    const [searchText, setSearchText] = useState('');
    const classes = useStyles();
    const { fetchWithUser } = useAuth()

    useEffect(() => {
        if (reload) {
            const url = `${config.apiUrl}/Examination/GetAllExaminationTypeList`;
            fetchWithUser(url)
                .then((response) => response.json())
                .then((data) => {
                    setExaminationTypes(data);
                });
            setReload(false);
        }
    }, [reload, fetchWithUser]);

    const requestSearch = (searchValue: any) => {
        setSearchText(searchValue);
    };

    const searchRegex = new RegExp(escapeRegExp(searchText), 'i');

    const filteredRows = examinationTypes.filter((row: any) => {
        return Object.keys(row).some((field) => {
            return searchRegex.test(row[field]);
        });
    });

    return (
        <>
            <MainContainer>
                <Grid container spacing={2}>
                    <Grid item container xs={12} spacing={1}>
                        <Grid item xs={6}>
                            <Typography className={classes.title}>Levels {" "}<NoiseControlOffIcon fontSize="small" /> {"   " + examinationTypes.length}</Typography>
                        </Grid>
                        <Grid item container xs={6} justifyContent="flex-end">
                            <Grid item>
                                <QuickSearchToolbar
                                    value={searchText}
                                    onChange={(event: any) => requestSearch(event.target.value)}
                                    clearSearch={() => requestSearch('')}
                                    placeholder="Search a level"
                                />
                            </Grid>
                            <Grid item >
                                <AddButton setOpen={setOpen}>New Level</AddButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <LevelsTable
                        setOpen={setOpen}
                        setExaminationType={setExaminationType}
                        rows={filteredRows}
                        setReload={setReload}
                    ></LevelsTable>
                </Grid>
                <LevelEditor
                    open={open}
                    onClose={() => {
                        setExaminationType({});
                        setOpen(false);
                    }}
                    onSave={() => {
                        setExaminationType({});
                        setOpen(false);
                        setReload(true);
                    }}
                    examinationType={examinationType}
                ></LevelEditor>

            </MainContainer >
        </>
    );
};

export default LevelsList;