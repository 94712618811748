import Participant from "../../entities/Participant";
import { Tooltip } from "@material-ui/core";
import DeleteButton from "../DeleteButton";
import { remove } from "./remove";
import { useAuth } from "../../context/Auth";

export interface ParticipantActionsProps {
  participant: Participant;
  setReload: (fetch: boolean) => {};
}

const ParticipantActions = ({
  participant,
  setReload
}: ParticipantActionsProps) => {
  const { fetchWithUser } = useAuth()
  return (
    <div
      style={{ cursor: "pointer" }}
    >
      <Tooltip title={`Remove ${participant.employee.firstName} from this event`} placement="top">
        <DeleteButton onClick={() => remove(participant.employee.firstName, participant.eventID, [participant.employeeID], setReload, fetchWithUser)}>
        </DeleteButton>
      </Tooltip>
    </div>
  );
};

export default ParticipantActions;