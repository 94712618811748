import React, { useEffect, useState } from "react";
import config from "../../config";
import {
  GridValueGetterParams,
} from "@material-ui/data-grid";
import ParticipantActions from "./ParticipantActions";
import Participant from "../../entities/Participant";
import { createStyles, Link, makeStyles } from "@material-ui/core";
import DataGrid from "../DataGrid"
import QuickSearchToolbar from '../QuickSearchToolbar'
import { useAuth } from "../../context/Auth";

const generateColumns = (props: any, departments: any) => [
  {
    field: "displayName",
    headerName: "Name",
    width: 150,
    valueGetter: (params: GridValueGetterParams) => {
      const { firstName, lastName } = params.row.employee;
      return `${lastName}, ${firstName}`;
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 200,
    valueGetter: (params: GridValueGetterParams) => params.row.employee.mail,
    renderCell: (params: any) => {
      const email = params.row.employee.mail;
      return <Link href={`mailto:${email}`}>{email}</Link>;
    },
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 150,
    valueGetter: (params: GridValueGetterParams) => {
      const { phone } = params.row.employee;
      return phone;
    },
  },
  {
    field: "department",
    headerName: "Department",
    width: 140,
    valueGetter: (params: GridValueGetterParams) => {
      const { departmentID } = params.row.employee;
      const department = departments.find((d: any) => d.key === departmentID);
      return department?.value;
    },
  },
  {
    field: "office",
    headerName: "Office",
    width: 120,
    valueGetter: (params: GridValueGetterParams) => {
      const { office } = params.row.employee;
      return office.officeName;
    },
  },
  {
    field: "TicketsNumber",
    headerName: "TicketsNumber",
    width: 150,
    valueGetter: (params: GridValueGetterParams) => {
      const { ticketsNumber } = params.row;
      return ticketsNumber;
    },
  },
  {
    field: "collectOffice",
    headerName: "Collect at",
    width: 130,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.officeCollect?.officeName;
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    disableExport: true,
    width: 120,
    renderCell: (params: any) => {
      return (
        <ParticipantActions
          participant={params.row}
          {...props}
        ></ParticipantActions>
      );
    },
  },
];

const getRowId = (row: any) => row.employee.employeeID;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: "12px",
    },
    toolbar: {
      display: "none",
    },
  })
);

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export default function ParticipantsTable(props: any) {
  const { reload, setReload, eventId, setIds } = props;
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [departments, setDepartments] = useState<any>([]);
  const classes = useStyles();
  const { fetchWithUser } = useAuth()

  useEffect(() => {
    fetchWithUser(`${config.apiUrl}/CodeTable/GetDepartmentList`)
      .then((response) => response.json())
      .then((data) => {
        setDepartments(data);
      });
  }, [fetchWithUser]);

  useEffect(() => {
    if (reload) {
      const url = `${config.apiUrl}/LiveLottery/GetEmployeesByLotteryEvent/${eventId}`;
      fetchWithUser(url)
        .then((response) => response.json())
        .then((data) => setParticipants(data));
      setReload(false);
    }
  }, [reload, setReload, eventId, fetchWithUser]);

  const fieldsName = ['employee.firstName', 'employee.lastName', 'employee.mail', 'employee.phone',
    'TicketsNumber', 'officeCollect.officeName', 'employee.department']

  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState<Participant[]>(participants);

  const isValueExistInRow = (row: any, field: any, searchRegex: any) => {
    let isexist: boolean = true;
    typeof row[field] == "object" ?
      isexist = Object.keys(row[field]).some((f: any) => {
        return fieldsName.includes(field + "." + f) && searchRegex.test(row[field][f])
      }) :
      isexist = fieldsName.includes(field) && searchRegex.test(row[field])
    return isexist;
  }

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = participants.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return row[field] && isValueExistInRow(row, field, searchRegex)
      });
    });
    setRows(filteredRows);
  };

  useEffect(() => {
    setRows(participants);
  }, [participants]);

  return (
    <DataGrid
      namespace="participants"
      className={classes.root}
      rows={rows}
      columns={generateColumns(props, departments)}
      getRowId={getRowId}
      components={{ Toolbar: QuickSearchToolbar }}
      componentsProps={{
        toolbar: {
          value: searchText,
          onChange: (event: any) => requestSearch(event.target.value),
          clearSearch: () => requestSearch(''),
        },
      }}
      checkboxSelection
      onSelectionModelChange={setIds}
    />
  );
}