import React, { useEffect, useState } from "react";
import ParticipantsTable from "./ParticipantsTable";
import { Grid } from "@material-ui/core";
import SelectGift from "./SelectGift";
import Employee from "../../entities/Employee";
import AddEveryone from "./AddEveryone";
import AddParticipant from "./AddParticipant";
import ExportCsv from "../ExportCsv";
import RemoveParticipants from "./RemoveParticipants";
import { escapeRegExp, GridRowId } from "@material-ui/data-grid";
import QuickSearchToolbar from "../QuickSearchToolbar";
import config from "../../config";
import { Participant } from "@microsoft/microsoft-graph-types";
import { useAuth } from "../../context/Auth";

const ParticipantsList = ({ eventId }: any) => {
  const [reload, setReload] = useState(true);
  const [open, setOpen] = useState(false);
  const [participant, setParticipant] = useState<any>({ eventID: eventId });
  const [ids, setIds] = useState<GridRowId[] | string[]>([]);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState<Participant[]>(participants);
  const { fetchWithUser } = useAuth()

  useEffect(() => {
    if (reload) {
      const url = `${config.apiUrl}/HolidayGift/GetEmployeesByGiftEvent/${eventId}`;
      fetchWithUser(url)
        .then((response) => response.json())
        .then((data) => setParticipants(data));
      setReload(false);
    }
  }, [reload, setReload, eventId, fetchWithUser]);

  const edit = (e: Employee) => {
    setParticipant(e);
    setOpen(true);
  };
  const onSave = () => {
    close();
    setReload(true);
  };
  const close = () => {
    setOpen(false);
    setParticipant({ eventID: eventId });
  };

  const fieldsName = ['employee.firstName', 'employee.lastName', 'employee.mail', 'comment', 'employee.phone',
    'holidayGift.holidayGiftName', 'officeCollect.officeName', 'employee.department']



  const isValueExistInRow = (row: any, field: any, searchRegex: any) => {
    let isexist: boolean = true;
    typeof row[field] == "object" ?
      isexist = Object.keys(row[field]).some((f: any) => {
        return fieldsName.includes(field + "." + f) && searchRegex.test(row[field][f])
      }) :
      isexist = fieldsName.includes(field) && searchRegex.test(row[field])
    return isexist;
  }

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = participants.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return row[field] && isValueExistInRow(row, field, searchRegex)
      });
    });
    setRows(filteredRows);
  };

  useEffect(() => {
    setRows(participants);
  }, [participants]);


  return (
    <>
      <Grid container spacing={1}>
        <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
          <Grid item>
            <QuickSearchToolbar
              value={searchText}
              onChange={(event: any) => requestSearch(event.target.value)}
              clearSearch={() => requestSearch('')} />          </Grid>
          <Grid item>
            <AddEveryone eventId={eventId} setReload={setReload} />
          </Grid>
          <Grid item>
            <AddParticipant eventId={eventId} setReload={setReload} />
          </Grid>
          <Grid item>
            <ExportCsv />
          </Grid>
          <Grid item>
            <RemoveParticipants ids={ids} eventId={eventId} setReload={setReload} />
          </Grid>
        </Grid>
        <ParticipantsTable
          eventId={eventId}
          reload={reload}
          setReload={setReload}
          edit={edit}
          setIds={setIds}
          rows={rows}
          participants={participants}
        ></ParticipantsTable>
      </Grid>
      <SelectGift
        open={open}
        onClose={close}
        onSave={onSave}
        participant={participant}
      ></SelectGift>
    </>
  );
};

export default ParticipantsList;