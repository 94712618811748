import React, { useState } from "react";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import { Button, CircularProgress, Tooltip } from "@material-ui/core";
import config from "../../config";
import { useAuth } from "../../context/Auth";

const AddEveryone = ({ eventId, setReload }: any) => {

  const [loading, setLoading] = useState(false);
  const { fetchWithUser } = useAuth()

  const addEveryone = () => {
    setLoading(true);
    const url = `${config.apiUrl}/HolidayGift/AddEmployeeGroupToGiftEvent?groupTypeID=${1}&eventID=${eventId}`;
    fetchWithUser(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }).then(_ => {
      setReload(true);
      setLoading(false);
    });
  };

  return (
    <Tooltip title="Adds all active employees" placement="top">
      <Button
        variant="contained"
        disableElevation
        size="medium"
        color="primary"
        onClick={addEveryone}
        disabled={loading}
      >
        <GroupAddIcon />
        Add Everyone
        {loading && <CircularProgress
          size={24}
        />}
      </Button>
    </Tooltip>
  );
};

export default AddEveryone;
