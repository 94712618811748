import config from "../../config";

export function remove(
  firstName: any,
  eventId: number,
  ids: number[],
  setReload: (b: boolean) => void,
  fetchWithUser: (endpoint: RequestInfo, options?: RequestInit, scopes?: string[])
    => Promise<Response>
) {
  let bool = true

  if (firstName) {
    bool = window.confirm(`Are you sure you want to delete ${firstName} from the list `)
  }
  else {
    bool = window.confirm("Are you sure you want to delete all the select participants")
  }

  const url = `${config.apiUrl}/LiveLottery/RemoveEmployeesToLotteryEvent?eventID=${eventId}`;
  if (bool) {
    fetchWithUser(url, {
      method: 'POST',
      body: JSON.stringify(ids),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((_) => {
        setReload(true);
      })
  }

}