import config from "../../config";
import { useAuth } from "../../context/Auth";
import Gift from "../../entities/Gift";
import DeleteButton from "../DeleteButton";
import EditButton from "../EditButton";

export interface GiftActionsProps {
  setReload: (fetch: boolean) => {};
  setOpen: (fetch: boolean) => {};
  open: boolean;
  gift: Gift;
  setGift: (gift: Gift) => void;
}

const GiftActions = ({
  gift,
  setOpen,
  setReload,
  setGift,
}: GiftActionsProps) => {
  const { fetchWithUser } = useAuth()
  const deleteGift = ({ holidayGiftID, selectionCount }: Gift) => {
    if (selectionCount)
      alert("You can't delete a gift that has been selected by employees");
    else if (
      window.confirm(`Are you sure you want to delete ${gift.holidayGiftName}`)
    ) {
      const url = `${config.apiUrl}/HolidayGift/DeleteHolidayGift?holidayGiftID=${holidayGiftID}`;
      fetchWithUser(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      })
        .then(() => {
          setReload(true);
        })
        .catch((_) =>
          alert("You can't delete a gift that has been selected by employees")
        );
    }
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-right"
        style={{ cursor: "pointer" }}
      >
        <EditButton color="primary"
          onClick={() => {
            setGift(gift);
            setOpen(true);
          }}
        />
        <DeleteButton
          disabled={gift.selectionCount ? true : false}
          onClick={() => {
            deleteGift(gift);
          }}
        />
      </div>
    </>
  );
};

export default GiftActions;
