import React, { useEffect, useState } from 'react'
import config from '../../../config';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import { Grid, Typography, Paper, Box } from '@mui/material';
import ExaminationCycle from '../../../entities/ExaminationCycle';
import { makeStyles } from '@material-ui/core';
import { useAuth } from '../../../context/Auth';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "rgba(250, 250, 250, 1)",
        height: "90vh",
        paddingTop: theme.spacing(9),
    },
    title: {
        paddingBottom: theme.spacing(4.5),
    },
    details: {
        marginLeft: theme.spacing(10)
    },
}));

export default function LevelDetails({ levelId }: any) {
    const [examinationType, setExaminationType] = useState<any>({});
    const [examinations, setExaminations] = useState<ExaminationCycle[]>([]);
    const { fetchWithUser } = useAuth()

    useEffect(() => {
        const url = `${config.apiUrl}/Examination/GetExaminationTypeByLevelId?levelId=${levelId}`;
        fetchWithUser(url)
            .then((response) => response.json())
            .then((data) => setExaminationType(data));

        const url1 = `${config.apiUrl}/Examination/GetExaminationListByLevelId?levelId=${levelId}`;
        fetchWithUser(url1)
            .then((response) => response.json())
            .then((data) => {
                setExaminations(data);
            });
    }, [levelId, fetchWithUser]);

    const classes = useStyles();
    return (
        <Grid className={classes.root}>
            <Grid container direction="column" className={classes.details}>
                <Grid item>
                    <Typography variant="h5" className={classes.title}>
                        {examinationType.examinationTypeName}
                    </Typography>
                </Grid>
                <Grid item container spacing={1}>
                    <Grid item>
                        <BookOutlinedIcon />
                    </Grid>
                    <Grid item xs>
                        <Box sx={{ fontWeight: "500" }}>Book</Box>
                        <Typography gutterBottom>
                            BookName
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container spacing={1}>
                    <Grid item>
                        <SupervisorAccountOutlinedIcon />
                    </Grid>
                    <Grid item xs={10} >
                        <Box sx={{ fontWeight: "500" }}>Participants</Box>
                        <Typography gutterBottom>
                            {examinationType.participantsNumber}
                        </Typography >
                    </Grid>
                </Grid>
                <Grid item >
                    <Typography variant='h6' gutterBottom style={{ paddingTop: "20px", fontWeight: "bold" }} >Test Details</Typography>
                </Grid>
                <Grid item >
                    <Box sx={{ fontWeight: "500" }}>Book</Box>
                </Grid>
                <Grid item >
                    <Typography gutterBottom>{examinationType.isOpenBook ? "Open" : "closed"}</Typography>
                </Grid>
                <Grid item >
                    <Box sx={{ fontWeight: "500" }}>Questions</Box>
                </Grid>
                <Grid item >
                    <Typography>{examinationType.isOpenQuestions ? "Open" : "closed"}</Typography>
                </Grid>
            </Grid>

            <Grid xs={10}>
                <Paper elevation={3} sx={{
                    mt: 8,
                    ml: 7.5,
                    textAlign: "center",
                    py: 3.5
                }}>
                    <Typography variant='h6'>Uncomming Tests</Typography>
                    {examinations.slice(examinations.length - 2, examinations.length).map((event: ExaminationCycle) =>
                        (<Typography key={event.examinationCycleID}>{event.submissionTime}</Typography>)
                    )}
                </Paper>
            </Grid>
        </Grid>
    )
}