import React, { /*useCallback,*/ useEffect, /*useMemo,*/ useState } from "react";
import ParticipantsTable from "./ParticipantsTable";
import { Grid } from "@material-ui/core";
import Employee from "../../../entities/Employee";
import ExportCsv from "../../ExportCsv";
import { GridFilterModel, GridLinkOperator, GridRowId } from "@mui/x-data-grid-pro";
import { useParams } from "react-router-dom";
import Participant from "../../../entities/Participant";
import config from "../../../config";
import QuickSearchToolbar from "../../QuickSearchToolbar";
import AddParticipant from "./AddParticipants";
import AdminIssueFilter from './Filter'
import { escapeRegExp } from "@material-ui/data-grid";
import { useAuth } from "../../../context/Auth";

const ParticipantsList = ({ levelId, filters }: any) => {

  const { id } = useParams<{ id: string }>();
  const [reload, setReload] = useState(true);
  const [open, setOpen] = useState(false);
  const [, setParticipant] = useState<any>({});
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [ids, setIds] = useState<GridRowId[] | string[]>([]);
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState<Participant[]>([])
  const { fetchWithUser } = useAuth()
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    linkOperator: GridLinkOperator.And
  })

  const fieldsName = ['employee.firstName', 'employee.lastName', 'employee.mail', 'employee.phone',
    'officeCollect.officeName', 'employee.department']

  const requestSearch = (searchValue: any) => {
    setSearchText(searchValue);
  };

  const isValueExistInRow = (row: any, field: any, searchRegex: any) => {
    let isexist: boolean = true;
    typeof row[field] == "object" ?
      isexist = Object.keys(row[field]).some((f: any) => {
        return fieldsName.includes(field + "." + f) && searchRegex.test(row[field][f])
      }) :
      isexist = fieldsName.includes(field) && searchRegex.test(row[field])
    return isexist;
  }
  const searchRegex = new RegExp(escapeRegExp(searchText), 'i')

  const filteredRows =
    participants.filter((row: any) => {
      return Object.keys(row).some(() => {
        return Object.keys(row).some((field: any) => {
          return row[field] && isValueExistInRow(row, field, searchRegex)
        });
      });
    });

  useEffect(() => {
    if (reload) {
      const url = `${config.apiUrl}/SHLCycle/GetEmployeesByExaminationType?examinationId=${levelId}`;
      fetchWithUser(url)
        .then((response) => response.json())
        .then((data) => setParticipants(data));
      setRows(filteredRows)
      setReload(false);
    }
  }, [reload, levelId, filteredRows, fetchWithUser]);

  useEffect(() => {

  })

  const edit = (e: Employee) => {
    setParticipant(e);
    setOpen(true);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
          <Grid item>
            <AdminIssueFilter
              filterModel={filterModel}
              setFilterModel={setFilterModel}
            ></AdminIssueFilter>
          </Grid>
          <Grid item>
            <QuickSearchToolbar
              value={searchText}
              onChange={(event: any) => requestSearch(event.target.value)}
              clearSearch={() => requestSearch('')} />
          </Grid>
          <Grid item>
            <AddParticipant levelId={levelId} shlCycleId={id} setIds={setIds} ids={ids} reload={reload} setReload={setReload} />
          </Grid>
          <Grid item>
            <ExportCsv />
          </Grid>
          <Grid item>
            {/* <RemoveParticipants ids={ids} shlCycleId={id} setReload={setReload} /> */}
          </Grid>
        </Grid>
        <ParticipantsTable
          shlCycleId={id}
          reload={reload}
          setReload={setReload}
          edit={edit}
          setIds={setIds}
          levelId={levelId}
          rows={filteredRows}
          filterModel={filterModel}
          open={open}
          ids={ids}
          row={rows}
        >
        </ParticipantsTable>
      </Grid>
      {/* <ParticipantEditor
          open={open}
          onClose={() => {
            setParticipant({ shlCycleID: shlCycleId });
            setOpen(false);
          }}
          onSave={() => {
            setParticipant({ shlCycleID: shlCycleId });
            setOpen(false);
            setReload(true);
          }}
          participant={participant}
        ></ParticipantEditor> */}

    </>
  );
};

export default ParticipantsList;


