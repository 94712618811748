import React, { useEffect, useState } from "react";
import config from "../../config";
import DataGrid from "../DataGrid";
import { createStyles, makeStyles } from "@material-ui/core";
import Lottery from "../../entities/Lottery";
import LotteryActions from "./LotteryAction";
import { GridToolbarExport } from "@material-ui/data-grid";
import LotteryParticipants from "./LotteryParticipants"
import { useAuth } from "../../context/Auth";


const generateColumns = (props: any, lottery: Lottery[]) => [
  { field: "lotteryID", headerName: "ID", hide: true },
  {
    field: "lotteryEnglishMame",
    headerName: "Raffle name",
    width: 200,
  },
  {
    field: "lotteryEnglishDesc",
    headerName: "Description",
    width: 200,
  },
  {
    field: "order",
    headerName: "Order",
    width: 120
  },
  {
    field: "lotteryNumber",
    headerName: "Raffle Number",
    width: 200
  },
  {
    field: "lotteriesAmount",
    headerName: "Raffels Amount",
    width: 200,
  },
  {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    disableExport: true,
    width: 140,
    renderCell: (params: any) => {
      return <LotteryActions lottery={params.row} {...props}></LotteryActions>;
    },
  },
  {
    field: "Lottery Participant",
    headerName: "",
    sortable: false,
    disableExport: true,
    width: 180,
    renderCell: (params: any) => {
      return <LotteryParticipants lotteryId={params.row.lotteryID}></LotteryParticipants>
    },
  },
];

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: "12px",
    },
    toolbar: {
      display: "none",
    },
  })
);

export default function LotteriesTable(props: any) {

  const { reload, setReload, eventId, setCount } = props;
  const [lotteries, setLotteries] = useState<Lottery[]>([]);
  const { fetchWithUser } = useAuth()
  const classes = useStyles();

  const getRowId = (row: any) => row.lotteryID;

  useEffect(() => {
    if (reload) {
      const url = `${config.apiUrl}/LiveLottery/GetLotteriesByEventID/${eventId}?lotteryTypeIDs=1&lotteryTypeIDs=2&lotteryTypeIDs=3&lotteryTypeIDs=4`;
      fetchWithUser(url)
        .then((response) => response.json())
        .then((data) => {
          setLotteries(data);
          setCount(data.length);
        });
      setReload(false);
    }
  }, [reload, setReload, eventId, setCount, fetchWithUser]);

  return (
    <DataGrid
      namespace="lottery"
      rows={lotteries}
      columns={generateColumns(props, lotteries)}
      getRowId={getRowId}
      components={{ Toolbar: () => <GridToolbarExport className={classes.toolbar} /> }}
    />
  );
}
