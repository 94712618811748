import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import config from "../../config";
import Event from "../../entities/Event";
import GiftsList from "../gifts/GiftsList";
import MainContainer from "../MainContainer";
import Tabs from "./Tabs";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import ParticipantsList from "../practicipants/ParticipantsList";
import LockedEvent from "./LockedEvent";
import { useAuth } from "../../context/Auth";

export default function EventPage() {
  const [event, setEvent] = useState<Event | null>(null);
  const { id } = useParams<{ id: string }>();
  let { path, url } = useRouteMatch();
  const { fetchWithUser } = useAuth()

  useEffect(() => {
    fetchWithUser(`${config.apiUrl}/Event/GetEvent/${id}`)
      .then((response) => response.json())
      .then((event: Event) => setEvent(event));
  }, [id, fetchWithUser]);

  const links = [
    {
      label: "Gifts",
      url: `${url}/gifts`,
    },
    {
      label: "Participants",
      url: `${url}/participants`,
    },
  ];

  return (
    <MainContainer title={event?.eventName}>
      {event?.isLock ? (<LockedEvent event={event} />) : (
        <>
          <Tabs tabs={links}></Tabs>
          <Switch>
            <Route path={`${path}/gifts`}>
              <GiftsList eventId={id}></GiftsList>
            </Route>
            <Route exact path={`${path}/participants`}>
              <ParticipantsList eventId={id}></ParticipantsList>
            </Route>
            <Redirect from={`${path}`} to={`${path}/gifts`} />
          </Switch>
        </>
      )}
    </MainContainer>
  );
}
