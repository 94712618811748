import config from "../../config";

export function remove(
  eventId: number,
  ids: number[],
  setReload: (b: boolean) => void,
  fetchWithUser: (
    endpoint: RequestInfo,
    options?: RequestInit,
    scopes?: string[]
  ) => Promise<Response>
) {
  const url = `${config.apiUrl}/HolidayGift/RemoveEmployeesToGiftEvent?eventID=${eventId}`;
  fetchWithUser(url, {
    method: "POST",
    body: JSON.stringify(ids),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((_) => {
    setReload(true);
  });
}
