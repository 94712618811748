import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  Switch,
  TextField,
} from "@material-ui/core";
import Modal from "../Modal";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { isValid } from "date-fns";
import config from "../../config";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Event from "../../entities/Event";
import LockButton from "../LockButton";
import { useAuth } from "../../context/Auth";

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    root: {
      "& > *": {
        margin: spacing(1),
      },
    },
  })
);

const getUrl = (event: Event) => {
  if (event && event.eventID) return `${config.apiUrl}/Event/UpdateEvent`;
  return `${config.apiUrl}/Event/AddEvent`;
};

const EventEditor = ({ event, onSave, ...props }: any) => {
  const [data, setData] = useState<any>(event);

  const classes = useStyles();

  const { fetchWithUser } = useAuth()

  const setValue = (key: string, value: any) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  useEffect(() => {
    setData(event);
  }, [event]);

  const onSubmit = () => {
    const url = getUrl(data);
    fetchWithUser(url, {
      method: 'POSt',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(() => onSave(data));
  };

  return (
    <Modal title="Add Event" onSave={onSubmit} {...props}>
      <form className={classes.root}>
        <TextField
          name="eventName"
          variant="outlined"
          fullWidth={true}
          label="Event Name"
          defaultValue={data.eventName}
          onChange={(e) => setValue("eventName", e.target.value)}
        ></TextField>

        <TextField
          variant="outlined"
          fullWidth={true}
          label="Event Description"
          name="eventDesc"
          defaultValue={data.eventDesc}
          onChange={(e) => setValue("eventDesc", e.target.value)}
        ></TextField>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk
            fullWidth={true}
            value={data.eventDate}
            defaultValue={data.eventDate}
            onChange={(date) => {
              if (isValid(date)) {
                setValue("eventDate", date);
              }
            }}
            format="dd-MM-yyyy"
            variant="inline"
            inputVariant="outlined"
          ></KeyboardDatePicker>
        </MuiPickersUtilsProvider>
        <FormControlLabel
          control={
            <Switch
              name="isEnabledSelection"
              color="primary"
              defaultChecked={data.isEnabledSelection}
              onChange={(e) => setValue("isEnabledSelection", e.target.checked)}
            />
          }
          label="Is Open for Selection"
        />

        <FormControlLabel
          control={
            <LockButton
              name="isLock"
              isLock={data.isLock}
              onChange={(value: boolean) => setValue("isLock", value)}
            />
          }
          label={data.isLock ? "Locked" : "Active"}
        />
      </form>
    </Modal>
  );
};

export default EventEditor;
