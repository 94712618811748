import React, { useEffect, useState } from "react";
import AddButton from "../../../AddButton";
import { Grid } from "@material-ui/core";
import TestEditor from "./TestEditor";
import QuickSearchToolbar from "../../../QuickSearchToolbar";
import ExaminationCycle from "../../../../entities/ExaminationCycle";
import config from "../../../../config";
import { escapeRegExp } from "@material-ui/data-grid";
import TestsTable from "./TestsTable";
import converStringDateToUtcDate from "../../../ConvertDate";
import ExportCsv from "../../../ExportCsv";
import { parse } from "date-fns";
import AdminIssueFilter from './TableFilters'
import { GridFilterModel, GridLinkOperator } from "@mui/x-data-grid-pro";
import MainContainer from "../../../MainContainer";
import { useAuth } from "../../../../context/Auth";


const TestsList = ({ levelId }: any) => {
    const [reload, setReload] = useState(true);
    const [open, setOpen] = useState(false);
    const [examination, setExamination] = useState<any>({
        // reminder1: new Date(Date.now()).toString(),
        // reminder2:new Date(Date.now()).toString(),
        // submissionTime: new Date(Date.now()).toString(),
    });
    const [examinations, setExaminations] = useState<ExaminationCycle[]>([]);
    const [rows, setRows] = useState<ExaminationCycle[]>(examinations);
    const [searchText, setSearchText] = useState('');
    const { fetchWithUser } = useAuth()
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [],
        linkOperator: GridLinkOperator.And
    })

    const edit = (e: ExaminationCycle) => {
        debugger
        setExamination({
            ...e,
            // eventDate: parse(e.eventDate, 'dd-MM-yyyy', new Date())

            submissionTime: parse(e.submissionTime && converStringDateToUtcDate(e.submissionTime.toString()), 'dd-MM-yyyy  HH:mm:ss', new Date()),
            reminder1: converStringDateToUtcDate(e.reminder1.toString()),
            reminder2: converStringDateToUtcDate(e.reminder2.toString()),
        });
        setOpen(true);
    };

    // useEffect(() => {
    //     setExamination({
    //         reminder1: converStringDateToUtcDate(new Date(Date.now()).toString()),
    //         reminder2:converStringDateToUtcDate(new Date(Date.now()).toString()),
    //         submissionTime: converStringDateToUtcDate(new Date(Date.now()).toString()),
    //     });
    // }, [])

    useEffect(() => {
        if (reload) {
            if (levelId !== undefined) {
                const url = `${config.apiUrl}/Examination/GetExaminationListByLevelId?levelId=${levelId}`;
                fetchWithUser(url)
                    .then((response) => response.json())
                    .then((data) => {
                        console.log("data", data)
                        setExaminations(data);
                    });
            }
            else {
                const url = `${config.apiUrl}/Examination/GetExaminationList`;
                fetchWithUser(url)
                    .then((response) => response.json())
                    .then((data) => {
                        setExaminations(data);
                    });
            }
            setReload(false);
        }
    }, [reload, setReload, levelId, fetchWithUser]);

    const requestSearch = (searchValue: any) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = examinations.filter((row: any) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });
        setRows(filteredRows);
    };

    useEffect(() => {
        setRows(examinations);
    }, [examinations]);

    return (
        <MainContainer title="tests">
            <Grid container spacing={2}>
                <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                    <Grid item>
                        <AdminIssueFilter
                            filterModel={filterModel}
                            setFilterModel={setFilterModel}
                        ></AdminIssueFilter>
                    </Grid>
                    <Grid item>
                        <Grid item xs={12} spacing={1}>
                            <QuickSearchToolbar
                                value={searchText}
                                onChange={(event: any) => requestSearch(event.target.value)}
                                clearSearch={() => requestSearch('')} />
                        </Grid>
                    </Grid>
                    <Grid item >
                        <AddButton setOpen={setOpen}>New Test</AddButton>
                    </Grid>
                    <Grid item>
                        <ExportCsv />
                    </Grid>
                </Grid>
                <TestsTable
                    reload={reload}
                    setReload={setReload}
                    setOpen={setOpen}
                    setExamination={setExaminations}
                    rows={rows}
                    examinations={examinations}
                    edit={edit}
                ></TestsTable>
            </Grid>
            <TestEditor
                examinationName={examination.examinationName}
                open={open}
                onClose={() => {
                    setExamination({
                        // reminder1: converStringDateToUtcDate(new Date(Date.now()).toString()),
                        // reminder2: converStringDateToUtcDate(new Date(Date.now()).toString()),
                        // submissionTime: converStringDateToUtcDate(new Date(Date.now()).toString())
                    });
                    setOpen(false);
                }}
                onSave={(e: ExaminationCycle) => {
                    setExamination({
                        // ...e,
                        // reminder1: converStringDateToUtcDate(new Date(Date.now()).toString()),
                        // reminder2: converStringDateToUtcDate(new Date(Date.now()).toString()),
                        // submissionTime: converStringDateToUtcDate(new Date(Date.now()).toString()),
                    })
                    setOpen(false);
                    setReload(true);
                }}
                examination={examination}
            ></TestEditor>
        </MainContainer>
    );
};
export default TestsList;