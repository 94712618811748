import { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import Modal from "../Modal";
import config from "../../config";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Participant from "../../entities/Participant";
import OfficeSelect from "../OfficeSelect";
import DepartmentSelect from "../DepartmentSelect";
import GiftSelect from "../GifttSelect";
import Gift from "../../entities/Gift";
import OutOfStockAlert from '../OutOfStockAlert';
import { useAuth } from "../../context/Auth";

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    root: {
      "& > *": {
        margin: spacing(1),
        width: "30ch",
      },
    },
    fullWidth: {
      width: `calc(100% - ${spacing(2)}px)`
    }
  })
);

const SelectGift = ({ participant, onSave, ...props }: any) => {
  const [data, setData] = useState<Participant>(participant);
  const [gift, setGift] = useState<Gift>();
  const [isHideAlert, setIsHideAlert] = useState(true)
  const { fetchWithUser } = useAuth()
  const classes = useStyles();

  const isSaveSelectGift = (value: boolean) => {
    if (value) {
      setIsHideAlert(true)
    }
    else {
      setIsHideAlert(true)
      setValue("holidayGiftID", null)
    }
  }

  const onGiftSelect = ((key: string, value: any) => {
    if (value.holidayGiftID !== null) {
      setGift(value)
      setIsHideAlert(false)
      setValue(key, value.holidayGiftID)
    }
    else {
      setValue("holidayGiftID", null)
    }
  })

  const setValue = (key: string, value: any) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  useEffect(() => {
    setData({
      ...participant,
      officeCollectID: participant.officeCollectID || participant.employee?.office?.officeID
    });
  }, [participant]);

  const onSubmit = () => {
    const url = `${config.apiUrl}/HolidayGift/UpdateEmployeeHolidayGift`;
    fetchWithUser(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(() => onSave(data));
  };

  return (
    <>
      {(participant && participant.employee) ?
        <Modal
          disabled={(gift?.isOutOfStock && !!!isHideAlert && data.holidayGiftID) || !!!data.holidayGiftID}
          title={`Select a gift for ${participant?.employee?.firstName}`}
          onSave={onSubmit}
          {...props}
        >
          <form className={classes.root}>
            <TextField
              variant="outlined"
              label="Name"
              name="name"
              defaultValue={`${participant.employee.firstName} ${participant.employee.lastName}`}
              disabled={true}
            ></TextField>
            <TextField
              variant="outlined"
              label="Email"
              name="email"
              defaultValue={participant.employee.mail}
              disabled={true}
            ></TextField>
            <OfficeSelect defaultValue={participant.employee.office.officeID} disabled={true} label="Office" />
            <DepartmentSelect defaultValue={participant?.employee?.department} disabled={true} />

            <GiftSelect giftId={data.holidayGiftID} eventId={participant.eventID} onChange={(id) => onGiftSelect("holidayGiftID", id)} />
            <OfficeSelect defaultValue={data.officeCollectID || participant.employee.office?.officeID} onChange={(id) => setValue("officeCollectID", id)} label="Office to collect" endpoint="GetOfficeToCollectList" />

            <TextField
              variant="outlined"
              fullWidth
              multiline
              label="Comment"
              name="comment"
              defaultValue={data.comment}
              onChange={(e) => setValue("comment", e.target.value)}
              className={classes.fullWidth}
            ></TextField>
          </form>
          {gift?.isOutOfStock && !isHideAlert && data.holidayGiftID ?
            <OutOfStockAlert isSaveSelectGift={isSaveSelectGift} /> : <></>}
        </Modal>
        : <></>}
    </>
  );
};

export default SelectGift;
