import React, { useEffect, useState } from "react";
import AddButton from "../AddButton";
import MainContainer from "../MainContainer";
import EventsTable from "./EventsTable";
import { Grid } from "@material-ui/core";
import EventEditor from "./EventEditor";
import Event from "../../entities/Event"
import { parse } from "date-fns";
import { useParams } from "react-router";
import config from "../../config";
import { useAuth } from "../../context/Auth";

const EventsList = () => {
  const [reload, setReload] = useState(true);
  const [open, setOpen] = useState(false);
  const { typeId } = useParams<{ typeId: string }>();
  const [event, setEvent] = useState<any>({ eventTypeID: typeId });
  const [events, setEvents] = useState<Event[]>([]);
  const { fetchWithUser } = useAuth()

  useEffect(() => {
    setEvent({ eventTypeID: typeId });
  }, [typeId])

  const edit = (e: Event) => {
    setEvent({
      ...e,
      eventDate: parse(e.eventDate, 'dd-MM-yyyy', new Date()),
    });
    setOpen(true);
  };

  const onSave = () => {
    close();
    setReload(true);
  };

  const close = () => {
    setOpen(false);
    setEvent({ eventTypeID: typeId });
  }

  useEffect(() => {
    fetchWithUser(`${config.apiUrl}/Event/GetEventList/?typeId=${typeId}`)
      .then((response) => response.json())
      .then((data) =>
        setEvents(data));
    setReload(false);
  }, [typeId, fetchWithUser]);

  return (
    <MainContainer title="Events" /*length={events.length}*/>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item container justifyContent="flex-end">
            <AddButton setOpen={setOpen}>Add Event</AddButton>
          </Grid>

        </Grid>
        <EventsTable
          reload={reload}
          setReload={setReload}
          edit={edit}
          typeId={typeId}
          events={events}
        ></EventsTable>
      </Grid>
      <EventEditor typeId={typeId} open={open} onClose={close} onSave={onSave} event={event}></EventEditor>
    </MainContainer>
  );
};

export default EventsList;