import React, { useEffect, useState } from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import Modal from "../../Modal";
import config from "../../../config";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ExaminationType from "../../../entities/ExaminationType";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useAuth } from "../../../context/Auth";

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    root: {
      "& > *": {
        margin: spacing(1),
      },
    },
  })
);

const isEdit = (examinationType: ExaminationType) => {
  return examinationType && examinationType.examinationTypeID;
};

const getUrl = (examinationType: ExaminationType) => {
  if (isEdit(examinationType)) return `${config.apiUrl}/Examination/UpdateExaminationType`;
  return `${config.apiUrl}/Examination/AddExaminationType`;
};

const LevelEditor = ({ examinationType, onSave, ...props }: any) => {
  const [data, setData] = useState<any>(examinationType);
  const [isSaving, setIsSaving] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { fetchWithUser } = useAuth()

  useEffect(() => {
    const { ...fields } = examinationType;
    setData(fields);
  }, [examinationType]);

  const setValue = (key: string, value: any) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const onSubmit = () => {
    setIsSaving(true)
    const url = getUrl(data);
    fetchWithUser(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => onSave(data))
      .catch(() => setOpen(true))
      .finally(() => setIsSaving(false))
  };

  return (
    <>
      <Modal
        maxWidth="sm"
        title={isEdit(examinationType) ? "Edit Level" : "Add Level"}
        onSave={onSubmit}
        {...props}
        disabled={isSaving}
      >
        <Grid>
          <form className={classes.root}>
            <TextField
              name="examinationTypeName"
              variant="outlined"
              label="Level Name"
              fullWidth={true}
              defaultValue={data.examinationTypeName}
              onChange={(e) => setValue("examinationTypeName", e.target.value)}
            />

            <Typography variant="h6" component="h2">Test Details</Typography>
            <FormControl sx={{ m: 1, minWidth: 260 }}>
              <InputLabel>Books</InputLabel>
              <Select
                value={data.isOpenBook}
                onChange={(e: any) => setValue("isOpenBook", e.target.value)}
                label="Is Open Book"
              >
                <MenuItem value={"true"}>Open</MenuItem>
                <MenuItem value={"false"}>Closed</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 260 }}>
              <InputLabel>Questions</InputLabel>
              <Select
                value={data.isOpenQuestions}
                onChange={(e: any) => setValue("isOpenQuestions", e.target.value)}
                label="Is Open Questions"
              >
                <MenuItem value={"true"}>Open</MenuItem>
                <MenuItem value={"false"}>Closed</MenuItem>
              </Select>
            </FormControl>
          </form>
        </Grid>
      </Modal>
      <Snackbar open={open} autoHideDuration={4000} onClose={(() => setOpen(false))}>
        <MuiAlert severity="error" sx={{ width: '100%' }}>
          Save Faild !
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default LevelEditor;